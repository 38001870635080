<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardBody>
      
            <h1 class="text-center">Coming Soon</h1>

    </CCardBody>
  </CCard>  
  
  </CCol>
    </CRow>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 



export default {
  // methods: {
  //   numberWithCommas(x) {
  //       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  //   }
  // },
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){

    this.loadData();
  },
  data: function() {
        return {
            products:[],
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    loadData: function() {
      if (this.tahun == null){
      // your code here.
        // axios
        // .get("https://probowsolution.com/sippd/api/public/index.php/User/2021?key=704b037a97fa9b25522b7c014c300f8a")
        // .then((response)=>
        // {
          // this.products = response.data;
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"api/public/index.php/withdrawals/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "payment_date",className: "text-center" },
                  { "data": "member_name" },
                  { "data": "amount_transaction", className: "text-center" },
                  { "data": "payment_method", className: "text-center" },
                  { "data": "payment_status", className: "text-center" },
                  // { "data": "edit" },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        // axios
        // .get("https://probowsolution.com/sippd/api/api/public/index.php/User/"+this.tahun+"?key=704b037a97fa9b25522b7c014c300f8a")
        // .then((response)=>
        // {
        //   this.products = response.data;
          $('#datatable').DataTable({
                      "processing": true,
              "serverSide": true,
               "pageLength": 50,
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"api/public/index.php/withdrawals/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
                       "dataType": "json",
                       "type": "POST"
                     },
              "columns": [
                  { "data": "payment_date",className: "text-center" },
                  { "data": "member_name" },
                  { "data": "amount_transaction", className: "text-center" },
                  { "data": "payment_method", className: "text-center" },
                  { "data": "payment_status", className: "text-center" },
                  // { "data": "edit" },
              ]  
          
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      
    }
  }
}
</script>